<template>
  <div id="main">
    <!-- 商业数据抬头+下划线 -->
    <h3 class="title" style="margin-left: 7px">
      商业统计
      <el-divider></el-divider>
    </h3>
    <!-- 统计数据总卡片 -->
    <div class="kefan malldata">
      <!-- 统计本体 -->
      <el-row :gutter="20">
        <el-col :span="6">
          <div>
            <h4>商业分类数量</h4>
            <div>
              <p>{{ amount }}</p>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <h4>总商家数量</h4>
          <div>
            <p>{{ count }}</p>
          </div>
        </el-col>

        <el-col :span="6">
          <h4>拥有商家最多的分类</h4>
          <div>
            <p v-for="item in classmaxtext" :key="item.mallname">
              {{ item.mallname }}
            </p>
          </div>
        </el-col>

        <el-col :span="6">
          <h4>页面总访问人数</h4>
          <div>
            <p>2000</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 商家展示抬头 -->
    <h3 class="title" style="margin-left: 7px">
      商家展示
      <el-divider></el-divider>
    </h3>
    <!-- 商业数据展示卡片 -->
    <div class="kefan mallshow">
      <!--下拉菜单  -->
      <div class="xhx">
        <el-select v-model="value" clearable placeholder="请选择商业分类">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- 增加商业数据的按钮 -->
        <button class="addmall" @click="dialogFormVisible = true">
          <span><i class="el-icon-circle-plus-outline"></i></span>
        </button>
      </div>
      <el-divider></el-divider>
      <!--数据展示卡片，一行4个  -->
      <div class="data">
        <el-row class="datarow" :gutter="20">
          <el-col v-for="item in malldetail" :key="item.id" :span="6">
            <el-card :body-style="{ padding: '0px' }" shadow="hover">
              <img :src="item.mchimg" class="image" />
              <div style="padding: 14px">
                <span>{{ item.mchname }}</span>
                <div class="bottom clearfix">
                  <span>{{ item.address }}</span>
                  <div class="button">
                    <i class="el-icon-edit" @click="alterform(item.id)"></i>
                    <i class="el-icon-delete" @click="deleteform(item.id)"></i>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      title="商业数据"
      :visible.sync="dialogFormVisible"
      destroy-on-close
      @close="clear"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="商家名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="on"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" :label-width="formLabelWidth" prop="region">
          <el-select v-model="form.region" placeholder="请选择所属分类">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家appID" :label-width="formLabelWidth" prop="appid" >
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="商家地址" :label-width="formLabelWidth" prop="address">
          <el-input
            v-model="form.address"
            placeholder="例:D区48号楼"
          ></el-input>
        </el-form-item>
        <el-form-item label="商家简介" :label-width="formLabelWidth" prop="content">
          <el-input
            v-model="form.content"
            maxlength="12"
            placeholder="最大输入12个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="商家图片" :label-width="formLabelWidth" required>
          <el-upload
            class="upload-demo"
            :action="imgurl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handlesuccess"
            :before-remove="beforeRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            list-type="picture-card"
            accept="image/png, image/jpeg"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit(form.id,'form')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      options: [],
      amount: 0,
      count: 0,
      classmaxtext: [],
      value: "",
      dialogFormVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      form: {
        id:null,
        name: "",
        region: "",
        appid: "",
        address: "",
        content: "",
      },
      rules:{
        name:[
          {required:true,message:"商家名称不能为空",trigger:'blur'}
        ],
        region:[
          {required:true,message:"请选择商家分类",trigger:'blur'}
        ],
        appid:[
          {required:true,message:"请输入商家小程序appid,可打开对应商家小程序查看资料获取",trigger:'blur'}
        ],
        address:[
          {required:true,message:"请输入商家地址",trigger:'blur'},
          {min:1,max:12,message:"长度为1-12个字符",trigger:'blur'}
        ],
        content:[
          {required:true,message:"请输入商家简介",trigger:'blur'},
          {min:1,max:12,message:"长度为1-12个字符",trigger:'blur'}
        ]
      },
      formLabelWidth: "120px",
      fileList: [],
      imglist: [],
      imgurl: this.$host + this.$url.upfile,
      malldetail: [],
    };
  },
  methods: {
    handlesuccess(response, file) {
      const a = {
        name: file.name,
        url: "/" + response,
      };
      this.imglist.push(a);
    },
    handleRemove(file, fileList) {
      this.imglist=[]
      this.fileList=[]
      console.log(file, fileList);
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}?`);
    },
    // 获取所有的分类
    getclasses() {
      axios({
        method: "get",
        url: this.$host + this.$url.classdata,
      }).then((res) => {
        // console.log(res.data)
        this.options = res.data;
        this.amount = this.options.length;
      });
    },
    //获取所有的商家数量
    getmallcount() {
      axios({
        method: "get",
        url: this.$host + this.$url.malldata,
      }).then((res) => {
        // console.log(res.data)
        this.count = res.data;
      });
    },
    // 获取数据最多的商家分类
    getmaxclass() {
      axios({
        method: "get",
        url: this.$host + this.$url.maxnum,
      }).then((res) => {
        // console.log(res.data)
        this.classmaxtext = res.data;
      });
    },
    // 获取全部商业数据详情
    getmalldata() {
      axios({
        method: "get",
        url: this.$host + this.$url.malldetail,
      }).then((res) => {
        // console.log(res.data)
        this.malldetail = res.data;
      });
    },
    // 根据分类的值筛选数据
    getcorrectdata(value) {
      axios({
        method: "post",
        url: this.$host + this.$url.correctdata,
        data: {
          name: value,
        },
      }).then((res) => {
        // console.log(res.data)
        this.malldetail = res.data;
      });
    },
    // 提交表单
    submit(id,form) {
      // console.log("提交前："+this.imglist)
      this.$refs[form].validate((valid)=>{
        if(valid){
          if(id!==null){
        axios({
          method:'post',
          url: this.$host+this.$url.alter,
          data: {
            id:id,
            form:this.form,
            fileList:this.imglist
          }
        }).then((res)=>{
          // console.log(res.data)
          if(res.data == "exist"){
            this.$message.error("商家数据已存在，请修改后重新输入");
          }else{
            this.$message.success("商家数据修改成功");
            this.dialogFormVisible = false;
            this.getclasses();
            this.getmallcount();
            this.getmaxclass();
            this.getmalldata();
          }
          
        })


      } else {
        axios({
          method: "post",
          url: this.$host + this.$url.form,
          data: {
            form: this.form,
            fileList: this.imglist,
          },
        }).then((res) => {
          console.log(res.data);
          if (res.data == "exist") {
            this.$message.error("商家数据已存在，请修改后重新输入");
            // this.dialogFormVisible = true
          } else if (res.data == "ok") {
            this.$message.success("商家数据录入成功");
            this.dialogFormVisible = false;
            this.getclasses();
            this.getmallcount();
            this.getmaxclass();
            this.getmalldata();
          }
        });
      }
        }else{
          this.$message.error("请正确填写表单")
          return false
        }
      })
      
    },
    // 编辑表单
    alterform(id) {
      axios({
        method: "post",
        url: this.$host + this.$url.mallalter,
        data: {
          id: id,
        },
      }).then((res) => {
        this.form.id = res.data.id
        this.form.name = res.data.mchname;
        this.form.region = res.data.classid;
        this.form.appid = res.data.mchsrc;
        this.form.address = res.data.address;
        this.form.content = res.data.content;
        this.imglist.push({url:res.data.mchimg});
        this.fileList.push({
          name: res.data.mchname + ".jpg",
          url: this.$houtaiurl + res.data.mchimg,
        }); 
        this.dialogFormVisible = true;
      });
    },
    //关闭时清空数据
    clear() {
      this.imglist = [];
      this.fileList = [];
      this.form = {
        id:null,
        name: "",
        region: "",
        appid: "",
        address: "",
        content: "",
      };
    },
    // 删除该卡片的数据
    deleteform(id){
      this.$confirm('此操作将永久删除该商户, 是否继续?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          axios({
            method:'post',
            url: this.$host+this.$url.delete,
            data: {
              id:id
            }
          }).then((res)=>{
            console.log(res.data)
            if(res.data=="ok"){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getclasses();
              this.getmallcount();
              this.getmaxclass();
              this.getmalldata();
            }else{
              this.$message({
                type:'error',
                message:'系统error,请联系管理员'
              })
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    }
  },
  mounted() {
    this.getclasses();
    this.getmallcount();
    this.getmaxclass();
    this.getmalldata();
  },
  watch: {
    value() {
      this.getcorrectdata(this.value);
    },
  },
};
</script>
<style  scoped>
#main {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 40px;
}
.title .el-divider--horizontal {
  width: 80px;
  margin: 5px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.malldata {
  text-align: center;
}
.xhx {
  padding-left: 60px;
}

/* 按钮 */
.addmall {
  position: absolute;
  right: 150px;
  display: inline-block;
  border-radius: 7px;
  border: none;
  background: #1875ff;
  color: white;
  font-family: inherit;
  text-align: center;
  font-size: 13px;
  /* box-shadow: 0px 14px 56px -11px #1875FF; */
  width: 10em;
  padding: 0.85em;
  transition: all 0.4s;
  cursor: pointer;
}

.addmall span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.addmall span:after {
  content: "商家数据";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -100px;
  transition: 0.7s;
}

.addmall:hover span {
  padding-right: 3.55em;
}

.addmall:hover span:after {
  opacity: 4;
  right: -10px;
}
/* 卡片样式 */
.data .datarow .el-col{
  margin-top: 20px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  height: 200px;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.data {
  padding: 0 30px;
}
.el-icon-edit {
  color: #1875ff;
  cursor: pointer;
}
.el-icon-delete {
  color: red;
  margin-left: 10px;
  cursor: pointer;
}
</style>